import VehicleManegmentPng from "../../Assets/vehicle-management-software.png";
import Shopifypng from "../../Assets/shopify.webp";
import Consult from "../../Assets/consult.webp";
import Development from "../../Assets/softwaredev.webp";

const ProductData = [
  {
    id: 1,
    name: "Rampapp - Kravu kontroles sistēma",
    description:
      "Pielāgojama un efektīva noliktavu un kravas automašīnu pārvaldības sistēma, kas automatizē kravu pieņemšanu, iekraušanu un izkraušanu, ietaupot uzņēmuma laiku un resursus.",
    imageUrl: VehicleManegmentPng,
    type: 1, //1 software/produkts-programmatūra 2= service/pakalpojums
    linkTo: "cargo-management-with-rampapp-solution-for-ease-of-cargo-control",
  },
  {
    id: 2,
    name: "Konsultācijas",
    description: "Saņemiet padomus un risinājumus jūsu uzņēmuma vajadzībām.",
    imageUrl: Consult, // Adjust the path accordingly"
    type: 2, //1 software 2= service
    linkTo:
      "primeit-it-consultation-web-mobile-app-development-custom-solutions",
  },
  {
    id: 3,
    name: "Internetveikalu izstrāde",
    description:
      "Piedāvājam Shopify internetveikalu izstrādi, papildināšanu, noformēšanu atbilstoši Jūsu vajadzībām.",
    imageUrl: Shopifypng, // Adjust the path accordingly
    type: 2, //1 software 2= service
    linkTo:
      "shopify-store-transform-your-business-ideas-into-reality-start-your-e-commerce-today",
  },
  {
    id: 4,
    name: "Individuāli pielāgotu aplikāciju izstrāde",
    description:
      "Izstrādājam pielāgotas mobilās un tīmekļa aplikācijas, kas atbilst jūsu specifiskajām prasībām.",
    imageUrl: Development, // Adjust the path accordingly
    type: 2, //1 software 2= service
    linkTo:
      "primeit-it-consultation-web-mobile-app-development-custom-solutions",
  },
];
export default ProductData;
