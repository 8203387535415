import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

interface ProductCardProps {
  product: {
    id: number;
    name: string;
    description: string;
    imageUrl: string;
    type: number;
    linkTo: string;
  };
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (product.linkTo) {
      navigate(`/blogs/${product.linkTo}`);
    }
  };

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          borderRadius: 2,
          height: "100%",
        }}
      >
        <div
          style={{
            backgroundColor: product.type === 1 ? "#3f51b5" : "#7900BF",
            color: "white",
            padding: "8px 16px",
            textAlign: "center",
            borderTopLeftRadius: 2,
            borderTopRightRadius: 2,
          }}
        >
          <Typography variant="h6">
            {product.type === 1 ? "Produkts" : "Pakalpojums"}
          </Typography>
        </div>
        <CardActionArea
          onClick={handleClick}
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardMedia
            component="img"
            height="140"
            image={product.imageUrl}
            alt={product.name}
          />
          <CardContent
            sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
          >
            <Box
              sx={{
                minHeight: "60px",
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
                textAlign: "left",
              }}
            >
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{
                  lineHeight: "1.2",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                }}
              >
                {product.name}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ flexGrow: 1 }}
            >
              {product.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default ProductCard;
