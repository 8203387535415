// src/Pages/IndividualBlogPage.tsx
import React from 'react';
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useApiContext } from "../Contexts/ApiContext";
import NewsList from "../Components/Organs/BlogList";
const IndividualBlogPage: React.FC = () => {
  const { getAllNews } = useApiContext();
  const theme = useTheme();

  return (
    <Box>
      {/* <Typography variant="h2" align="center">Latest News</Typography> */}
      <NewsList fetchNewsFunction={getAllNews} />
    </Box>
  );
};

export default IndividualBlogPage;
