import React, { useEffect } from "react";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import { useApiContext } from "../../Contexts/ApiContext";
import { useNavigate } from "react-router-dom";

const Blog3 = () => {
  const { news, fetchLatestThreeBlogs } = useApiContext();
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the latest three blog posts
    fetchLatestThreeBlogs();
  }, [fetchLatestThreeBlogs]);

  return (
    <Box
      sx={{
        maxWidth: "80%",
        margin: "auto",
        padding: "40px 30px",
      }}
    >
      <Typography textAlign={"center"} variant={"h3"} color="black" paddingBottom={4}>
        Jaunākie blogi
      </Typography>
      <Grid container spacing={3}>
        {news.map((item, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card
              elevation={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "16px", // Rounded corners
                overflow: "hidden",
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.05)", // Scale on hover
                },
                height: "100%", // Ensure consistent card height
              }}
              onClick={() => navigate(`/blogs/${item.id}`)}
            >
              <CardMedia
                component="img"
                image={item.imageUrl}
                alt={item.title}
                sx={{
                  height: 140,
                  borderTopLeftRadius: "16px",
                  borderTopRightRadius: "16px",
                }} // Rounded corners for image
              />
              <CardContent sx={{ flexGrow: 1, minHeight: 150 }}>
                {" "}
                {/* Fixed height for content */}
                <Typography variant="h5" component="h2">
                  {item.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.shortDescription}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Blog3;
