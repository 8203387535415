import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import IndividualBlogPage from "./Pages/IndividualBlogPage";
import BlogListPage from "./Pages/BlogListPage";
import { Container, ThemeProvider } from "@mui/material";
import theme from "./Theme/theme";
import NavBar from "./Components/Organs/NavBar";

import ProtectedRoute from "./Dash/Protected/ProtectedRoute";
import Dashboard from "./Dash/Dashboard";
import Login from "./Dash/Login/Login";
import { AuthProvider } from "./Contexts/Auth";
import { ApiProvider } from "./Contexts/ApiContext";
import News from "./Dash/ChildrenComponents/News/News";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <NavBar />
        <HomePage />
      </>
    ),
  },
  {
    path: "/blogs",
    element: (
      <>
        <NavBar />
        <BlogListPage />
      </>
    ),
  },
  // {
  //   path: "/products",
  //   element: (
  //     <>
  //       <NavBar />
  //       <ProductPage />
  //     </>
  //   ),
  // },
  
  {
    path: "/blogs/:blogId",
    element: (
      <>
        <NavBar/>
        <IndividualBlogPage/>
      </>
    ),
  },
  {
    path: "/login",
    element: (
      <>
        <NavBar />
        <Login />
      </>
    ),
  },
  {
    path: "/admin-dashboard",
    element: (
      <ProtectedRoute>
        <>
          <NavBar />
          <Dashboard />
        </>
      </ProtectedRoute>
    ),
    children: [
      {
        path: "news",
        element: (
          <ProtectedRoute>
            <News />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ApiProvider>
        <ThemeProvider theme={theme}>
          <Container
            maxWidth={false}
            sx={{
              paddingLeft: 0,
              paddingRight: 0,
              "@media (min-width:600px)": {
                padding: 0,
              },
              "@media (min-width:900px)": {
                padding: 0,
              },
            }}
          >
            <RouterProvider router={router} />
          </Container>
        </ThemeProvider>
      </ApiProvider>
    </AuthProvider>
  );
};

export default App;
