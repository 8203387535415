import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApiContext } from "../Contexts/ApiContext";
import { Container, Box, Typography, CircularProgress } from "@mui/material";
import ContactUs from "../Components/Organs/ContactUs";

interface BlogPost {
  id?: string;
  title: string;
  imageUrl: string;
  content: string;
}

const IndividualBlogPage = () => {
  const [blogPost, setBlogPost] = useState<BlogPost | null>(null);
  const [loading, setLoading] = useState(true);
  const { blogId } = useParams<{ blogId: string }>();
  const { getNewsById } = useApiContext();

  useEffect(() => {
    const fetchData = async () => {
      if (blogId) {
        const data = await getNewsById(blogId);
        setBlogPost(data);
        setLoading(false);
      }
    };

    fetchData();
  }, [blogId, getNewsById]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!blogPost) {
    return (
      <Container>
        <Typography variant="h6">Blog post not found.</Typography>
      </Container>
    );
  }

  return (
    <Box>
      <Container>
        <Typography variant="h5" gutterBottom className="custom">
          {blogPost.title}
        </Typography>
        <Box
          component="img"
          src={blogPost.imageUrl}
          alt={blogPost.title}
          sx={{ width: "100%", height: "auto", marginBottom: "20px" }}
        />
        <div
          className="custom"
          dangerouslySetInnerHTML={{ __html: blogPost.content }}
        />
      </Container>
      <ContactUs />
    </Box>
  );
};

export default IndividualBlogPage;
