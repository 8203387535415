import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import ProductCard from '../Organisms/ProductCard';
import ProductsData from '../Molecules/ProductsData';

const Products: React.FC = () => {
  const handleCardClick = (id: number) => {
    console.log(`Product ${id} clicked`);
    // Navigate to the product detail page or handle the click event here
  };

  return (
    <Box
    id="produkti"
      sx={{
        maxWidth: '95%',
        margin: 'auto',
        padding: '40px 30px',
      }}
    >
      <Typography textAlign={'center'} variant={'h4'} color="black" padding={3}>

      </Typography>
      <Grid container spacing={3}>
        {ProductsData.map((product) => (
          <ProductCard
            key={product.id}
            product={product}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default Products;
