import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { useApiContext } from "../../Contexts/ApiContext";
import { useNavigate } from "react-router-dom";

interface NewsListProps {
  fetchNewsFunction: () => Promise<void>;
}

const NewsList = ({ fetchNewsFunction }: NewsListProps) => {
  const { news } = useApiContext();
  const navigate = useNavigate();

  useEffect(() => {
    fetchNewsFunction();
  }, [fetchNewsFunction]);

  return (
    <Box
      sx={{
        maxWidth: "95%",
        margin: "auto",
        padding: "40px 30px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography textAlign={"center"} variant={"h2"} color="black">
        Visi Blogi
      </Typography>
      {news.map((news, index) => (
        <Card
          key={index}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" }, // Image on top for small screens
            backgroundColor: "transparent",
            marginBottom: 2,
            borderRadius: 0,
            overflow: "hidden",
            paddingTop: 10,
            boxShadow: "none",
          }}
        >
          <CardMedia
            component="img"
            sx={{
              width: { xs: "100%", md: "25%" }, // Full width for small screens
              objectFit: "cover",
              clipPath: {
                xs: "none",
                md: "polygon(5% 0, 100% 0, 95% 100%, 0 100%)",
              }, // Full image for small screens
            }}
            image={news.imageUrl}
            alt={news.title}
          />
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              color: "black",
              flexGrow: 1, // Allow content to grow
              padding: 2,
            }}
          >
            <Typography
              variant="h5"
              component="h2"
              sx={{ wordBreak: "break-word" }}
            >
              {news.title}
            </Typography>
            <Typography
              variant="body1"
              sx={{ wordBreak: "break-word", marginBottom: 2 }}
            >
              {news.shortDescription}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Button
                variant="outlined"
                onClick={() => navigate(`/blogs/${news.id}`)} // Update this line
                sx={{
                  backgroundColor: "transparent",
                  color: "#0f003c",
                  borderWidth: "2px",
                  borderColor: "#0f003c",
                  "&:hover": {
                    borderColor: "#0f003c",
                    transform: "scale(1.3)",
                    backgroundColor: "transparent",
                  },
                  fontSize: "0.75rem",
                  padding: "6px 12px",
                  maxWidth: "fit-content",
                  fontWeight: "bold",
                  transition: "transform 0.3s ease-in-out",
                }}
              >
                Read More
              </Button>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default NewsList;
