import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import logo from "../../Assets/Logos/BlackThemeLogo.png";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const NavBar: React.FC = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const pages = ["Sākums", "Produkti", "Par mums", "Kontakti", "Blogs"];
  const navigate = useNavigate();
  const location = useLocation();
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 });

  const checkIsHomePage = () => {
    return location.pathname === "/";
  };

  const checkIsBlogsPage = () => {
    return location.pathname === "/blogs";
  };

  const [isHomePage, setIsHomePage] = useState(checkIsHomePage());
  const [isBlogsPage, setIsBlogsPage] = useState(checkIsBlogsPage());

  useEffect(() => {
    setIsHomePage(checkIsHomePage());
    setIsBlogsPage(checkIsBlogsPage());
  }, [location.pathname]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavigate = (page: string) => {
    handleCloseNavMenu();
    const sectionId = page.toLowerCase();
    if (page === "Blogs") {
      navigate("/blogs");
      window.scrollTo(0, 0); // Scroll to top when opening the blog page
    } else if (isHomePage) {
      document.getElementById(sectionId)?.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      navigate(`/?section=${sectionId}`);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sectionId = params.get("section");
    if (sectionId && isHomePage) {
      setTimeout(() => {
        document.getElementById(sectionId)?.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 100);
    }
  }, [location, isHomePage]);

  return (
    <React.Fragment>
      <AppBar
        position={trigger || !isHomePage ? "sticky" : "absolute"}
        sx={{
          backgroundColor: isBlogsPage ? "#1e0178" : trigger || !isHomePage ? "white" : "transparent",
          color: isBlogsPage ? "white" : trigger || !isHomePage ? "black" : "white",
          transition: "0.3s",
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, display: { md: "none" } }}
            onClick={handleOpenNavMenu}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {pages.map((page) => (
              <MenuItem key={page} onClick={() => handleNavigate(page)}>
                <Typography textAlign="center">{page}</Typography>
              </MenuItem>
            ))}
          </Menu>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              fontWeight: 800,
            }}
          >
            <img src={logo} alt="Logo" height="40px" />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                sx={{ my: 2, color: "inherit", display: "block" }}
                onClick={() => handleNavigate(page)}
              >
                <Typography variant="body2" sx={{ fontWeight: 400 }}>
                  {page}
                </Typography>
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default NavBar;
